import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Helpers from "@/functions/helpers";
import "bootstrap/dist/css/bootstrap.min.css"
import './registerServiceWorker'

Array.prototype.asyncForEach = async function (callback, thisArg) {
    thisArg = thisArg || this;
    for (let i = 0, l = this.length; i !== l; ++i) {
        await callback.call(thisArg, this[i], i, this)
    }
};

const app = createApp(App);

app.config.globalProperties = {
    Helpers
};

app.use(store).use(router).mount('#app')