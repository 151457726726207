import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class FantasyService {
    static async getNews(type) {
        const failSafeType = type ? type : 'composite'
        const response = await axios.post(`${environmentConfig.fantasyHost}/news/get-news-feed-items`, {
            sportId: 1,
            type: failSafeType
        })
        return response.data.recordset;
    }

    static async getUserStats(msisdn) {
        return await axios.post(`${environmentConfig.fantasyHost}/profile/get-user-stats`, {
            msisdn: msisdn
        })
    }
    
    static async autoPickOptIn(userToken, msisdn, isOptIn) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/auto-pick-opt-in`, {
            token: userToken,
            msisdn: msisdn,
            isOptIn: isOptIn,
        })
    }

    static async getUserRoundEntries(roundId, userId, userToken) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/get-leaderboard-user-entries`, {
            id: roundId,
            userId: userId,
            userToken: userToken
        })
    }

    static async getUserEntries(roundId, msisdn) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/get-user-entries`, {
            id: roundId,
            msisdn: msisdn
        })
    }

    static async activeRounds(msisdn) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-upcoming-rounds`, {
            msisdn: msisdn
        })
    }

    static async getAutoSelectedTeam(roundId) {
        return await axios.get(`${environmentConfig.fantasyHost}/soccer/get-auto-entries/?roundId=${roundId}`)
    }

    static async postUserEntries(collection, msisdn) {
        await this.deleteUserEntries(collection[0].roundId, msisdn)
        return axios.post(`${environmentConfig.fantasyHost}/soccer/post-user-entries`, {
            collection: collection,
            userAccessId: 1,
            msisdn: msisdn
        })
    }

    static async deleteUserEntries(roundId, msisdn) {
        return axios.post(`${environmentConfig.fantasyHost}/soccer/delete-user-entries`, {
            roundId,
            msisdn
        })
    }

    static async getRoundPlayers(pageNumber, playerPosition, roundId, rowsOfPage, userToken) {
        const formattedPosition = playerPosition === "Midfielder1" || playerPosition === "Midfielder2" ? "Midfielder" : playerPosition;
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-round-players`, {
            pageNumber,
            position: formattedPosition,
            roundId,
            rowsOfPage,
            userToken
        })
    }

    static async getRoundDefences(roundId) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-round-defences`, {
            roundId,
        })
    }

    static async getLeagues() {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-leagues`, {
            sportId: 1
        });
    }

    static async getLeagueTopPicks(leagueAbbreviation, season) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-top-players-in-league`, {
            ref: leagueAbbreviation,
            seasonRef: season
        });
    }

    static async getCompletedRounds(msisdn, pageNumber, rowsOfPage) {
        return await axios.post(`${environmentConfig.fantasyHost}/soccer/get-completed-rounds`, {
            msisdn: `${msisdn}`,
            pageNumber,
            rowsOfPage,
        })
    }

    static async getRoundLeaderboard(roundId, msisdn, userAccessId) {
        const response = await axios.post(`${environmentConfig.fantasyHost}/soccer/get-round-leaders`, {
            id: roundId,
            userAccessId,
            msisdn: `${msisdn}`
        })
        return response.data
    }

    static async getLeaderboard(msisdn) {
        const response = await axios.post(`${environmentConfig.fantasyHost}/profile/get-leaderboard`, {
            msisdn: msisdn,
            sportId: 1,
        })
        return response.data.recordsets
    }
}
