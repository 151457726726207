import dotenv from 'dotenv';

dotenv.config();

export const productionConfig = {
    token: `${process.env.VUE_APP_TEST_TOKEN}`,
    fantasyHost: `${process.env.VUE_APP_FANTASY_SERVICE}`,
    predictorService: `${process.env.VUE_APP_PREDICTOR_SERVICE}`,
    freePlayPredictorHost: `${process.env.VUE_APP_FREEPLAY_PREDICTOR_HOST}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
}

export const stagingConfig = {
    token: `${process.env.VUE_APP_TEST_TOKEN}`,
    fantasyHost: `${process.env.VUE_APP_STAGING_FANTASY_SERVICE}`,
    predictorService: `${process.env.VUE_APP_PREDICTOR_SERVICE}`,
    freePlayPredictorHost: `${process.env.VUE_APP_FREEPLAY_PREDICTOR_HOST}`,
    upstreamService: `${process.env.VUE_APP_STAGING_UPSTREAM_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    var base_url = window.location.origin;
    if (base_url.includes('staging')) return 'production'
    if (base_url.includes('localhost')) return 'production'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return stagingConfig
    if (environment === 'production') return productionConfig
}
