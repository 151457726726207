<template>
  <div class="d-flex flex-column align-items-center">
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container">
      <LoadingOverlay class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3" v-if="this.isLoading"/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import {setBrandConfig} from "@/functions/setBrandConfig";
import {mapState, mapMutations} from "vuex";
import FantasyService from "@/services/fantasyService";

export default {
  computed: {
    ...mapState(['isLoading'])
  },
  components: {
    LoadingOverlay
  },
  methods: {
    ...mapMutations(['setDeferredPrompt', 'setFeedArticles']),
    captureEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.setDeferredPrompt(e)
      })
    },
    setFavicon() {
      const faviconLink = document.getElementById('favicon');
      faviconLink.href = './img/icons/android-chrome-192x192.png';
      let manifest = {
        name: "App name",
        icons: [{
          src: `./img/icons/android-chrome-192x192.png`,
          sizes: "192x192",
          type: "image/png"
        }, {
          src: `./img/icons/android-chrome-512x512.png`,
          sizes: "512x512",
          type: "image/png"
        }, {
          src: `./img/icons/android-chrome-maskable-192x192.png`,
          sizes: "192x192",
          type: "image/png"
        }, {
          src: `./img/icons/android-chrome-maskable-512x512.png`,
          sizes: "512x512",
          type: "image/png"
        }]
      };
      let content = encodeURIComponent(JSON.stringify(manifest));
      let url = "data:application/manifest+json," + content;
      let element = document.createElement('link');
      element.setAttribute('rel', 'manifest');
      element.setAttribute('href', url);
      document.querySelector('head').appendChild(element);
    },
    async setNewsFeed() {
      this.setFeedArticles(await FantasyService.getNews());
    },
  },
  beforeMount() {
    setBrandConfig();
    this.setFavicon();
    this.setNewsFeed();
    this.captureEvent();
  }
}
</script>

<style lang="scss">
@import "../public/globalStyles/global";

.vue-container {
  position: relative;
  background-image: url("assets/backgrounds/bg-main.webp");
  background-size: cover;
  height: 100vh;
  overflow-x: hidden;
  border: .5px solid #636363;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.vue-container::-webkit-scrollbar {
  display: none;
}

#ot-sdk-btn-floating.ot-floating-button {
  bottom: 50px !important;
  width: 30px !important;
  height: 30px !important;
}
</style>
