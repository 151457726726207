export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomSouthAfricaEnglish: 'vc-za-en',
};

brandConfigObjectMap.set(Brands.VodacomSouthAfricaEnglish, {
    gameTitle: 'Vodacom Fantasy Football',
    freePlayServiceId: 'soccer-free',
    baseRoute: '/home',
    revenuePartnerId: 1,
    isRandomDrawAwarded: false,
    tabTitle: 'Fantasy Football',
    userAccessId: 1,
    googleTag: 'G-JGRR0HQX82'
});

