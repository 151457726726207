import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";

const routes = [
    {
        path: '/',
        redirect: getBaseRoute(),
    },
    {
        path: '/home',
        name: 'HomeView',
        component: () => import("@/views/HomeView.vue"),
    },
    {
        path: '/request-otp',
        name: 'RequestOtp',
        component: () => import("@/views/onboarding/RequestOtp.vue"),
    },
    {
        path: '/submit-otp',
        name: 'SubmitOtp',
        component: () => import("@/views/onboarding/SubmitOtp.vue"),
    },
    {
        path: '/subscription',
        name: 'SubscriptionPackage',
        component: () => import("@/views/onboarding/SubscriptionPackage.vue"),
    },
    {
        path: '/confirm-subscription/:price/:interval',
        name: 'ConfirmSubscription',
        component: () => import("@/views/onboarding/ConfirmSubscription.vue"),
    },
    {
        path: '/menu',
        name: 'MenuView',
        component: () => import("@/views/MenuView.vue"),
    },
    {
        path: '/prizes',
        name: 'PrizesView',
        component: () => import("@/views/PrizesView.vue"),
    },
    {
        path: '/fantasy',
        name: 'FantasyView',
        component: () => import("@/views/FantasyView.vue"),
        children: [
            {
                path: 'home',
                name: 'FantasyHome',
                component: () => import("@/views/fantasy/FantasyHome.vue"),
            },
            {
                path: 'how-to-play',
                name: 'FantasyHowToPlay',
                component: () => import("@/views/fantasy/FantasyHowToPlay.vue"),
            },
            {
                path: 'auto-team-builder',
                name: 'FantasyAutoTeamBuilder',
                component: () => import("@/views/fantasy/FantasyAutoTeamBuilder.vue"),
            },
            {
                path: 'season-ranking',
                name: 'FantasySeasonRanking',
                component: () => import("@/views/fantasy/FantasySeasonRanking.vue"),
            },
            {
                path: 'top-picks',
                name: 'FantasyTopPicks',
                component: () => import("@/views/fantasy/FantasyTopPicks.vue"),
            },
            {
                path: 'upcoming-rounds',
                name: 'FantasyUpcomingRounds',
                component: () => import("@/views/fantasy/FantasyUpcomingRounds.vue"),
            },
            {
                path: 'team-builder',
                name: 'FantasyTeamBuilder',
                component: () => import("@/views/fantasy/FantasyTeamBuilder.vue"),
            },
            {
                path: 'team-selector',
                name: 'FantasyTeamSelector',
                component: () => import("@/views/fantasy/FantasyTeamSelector.vue"),
            },
            {
                path: 'my-rounds',
                name: 'FantasyMyRounds',
                component: () => import("@/views/fantasy/FantasyMyRounds.vue"),
            },
            {
                path: 'my-results',
                name: 'FantasyMyResults',
                component: () => import("@/views/fantasy/FantasyMyResults.vue"),
            },
            {
                path: 'round-leaderboard/:roundId',
                name: 'FantasyRoundLeaderboard',
                component: () => import("@/views/fantasy/FantasyRoundLeaderboard.vue"),
            },
        ]
    },
    {
        path: '/predictor',
        name: 'PredictorView',
        component: () => import("@/views/PredictorView.vue"),
        children: [
            {
                path: 'home',
                name: 'PredictorHome',
                component: () => import("@/views/predictor/PredictorHome.vue"),
            },
            {
                path: 'how-to-play',
                name: 'PredictorHowToPlay',
                component: () => import("@/views/predictor/PredictorHowToPlay.vue"),
            },
            {
                path: 'prizes',
                name: 'PredictorPrizes',
                component: () => import("@/views/predictor/PredictorPrizes.vue"),
            },
            {
                path: 'upcoming-rounds',
                name: 'PredictorUpcomingRounds',
                component: () => import("@/views/predictor/PredictorUpcomingRounds.vue"),
            },
            {
                path: '/make-predictions/:roundId',
                name: 'PredictorMakePredictions',
                component: () => import("@/views/predictor/PredictorMakePredictions.vue"),
            },
            {
                path: 'my-rounds',
                name: 'PredictorMyRounds',
                component: () => import("@/views/predictor/PredictorMyRounds.vue"),
            },
            {
                path: 'leaderboard',
                name: 'PredictorLeaderboard',
                component: () => import("@/views/predictor/PredictorLeaderboard.vue"),
            },
            {
                path: 'results',
                name: 'PredictorResults',
                component: () => import("@/views/predictor/PredictorResults.vue"),
            },
            {
                path: 'round-leaderboard/:roundId',
                name: 'PredictorRoundLeaderboard',
                component: () => import("@/views/predictor/PredictorRoundLeaderboard.vue"),
            },
        ]
    },
    {
        path: '/trivia',
        name: 'TriviaView',
        component: () => import("@/views/TriviaView.vue"),
        children: [
            {
                path: 'home',
                name: 'TriviaHome',
                component: () => import("@/views/trivia/TriviaHome.vue"),
            },
            {
                path: 'questions',
                name: 'TriviaQuestions',
                component: () => import("@/views/trivia/TriviaQuestions.vue"),
            },
            {
                path: 'results',
                name: 'TriviaResults',
                component: () => import("@/views/trivia/TriviaResults.vue"),
            },
            {
                path: 'leaderboard',
                name: 'TriviaLeaderboard',
                component: () => import("@/views/trivia/TriviaLeaderboard.vue"),
            },
        ]
    },
    {
        path: '/news',
        name: 'NewsView',
        component: () => import("@/views/NewsView.vue"),
        children: [
            {
                path: 'home',
                name: 'NewsHome',
                component: () => import("@/views/news/NewsHome.vue"),
            },
            {
                path: 'articles',
                name: 'NewsArticles',
                component: () => import("@/views/news/NewsArticles.vue"),
            },
            {
                path: 'videos',
                name: 'NewsVideos',
                component: () => import("@/views/news/NewsVideos.vue"),
            },
            {
                path: 'article/:articleId',
                name: 'NewsArticle',
                component: () => import("@/views/news/NewsArticle.vue"),
            },
            {
                path: 'video/:videoId',
                name: 'NewsVideo',
                component: () => import("@/views/news/NewsVideo.vue"),
            },
        ]
    },
    {
        path: '/settings',
        name: 'SettingsView',
        component: () => import("@/views/SettingsView.vue"),
        children: [
            {
                path: 'menu',
                name: 'SettingsMenu',
                component: () => import("@/views/settings/SettingsMenu.vue"),
            },
            {
                path: 'change-username',
                name: 'ChangeUsername',
                component: () => import("@/views/settings/ChangeUsername.vue"),
            },
            {
                path: 'manage-subscription',
                name: 'ManageSubscription',
                component: () => import("@/views/settings/ManageSubscription.vue"),
            },
            {
                path: 'update-notifications',
                name: 'UpdateNotifications',
                component: () => import("@/views/settings/UpdateNotifications.vue"),
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

function getBaseRoute() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
    return brandConfig.baseRoute;
}

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

// router.afterEach((to, from, next) => utagDataPost(to, from, next));

export default router

