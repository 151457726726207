import UpstreamService from "@/services/upstreamService";
import store from "../store";

export async function userAuthenticate(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const localStorageToken = localStorage.getItem('token');
    const fantasyToken = urlParams.get('fantasyToken') || localStorageToken;
    const allowedRoutes = ['HomeView', 'NewsHome', 'MenuView', 'RequestOtp', 'PrizesView', 'NewsArticle', 'NewsArticles', 'NewsVideo', 'NewsVideos'];
    if (store.state.user) {
        return next();
    }

    if (allowedRoutes.includes(to.name) && !fantasyToken) {
        return next();
    }

    if (!allowedRoutes.includes(to.name) && !fantasyToken) {
        return next({name: 'RequestOtp'});
    }

    if (fantasyToken) {
        const user = (await UpstreamService.getUserStatus(fantasyToken)).data;
        localStorage.setItem('token', fantasyToken);

        store.commit('setUser', user);

        if (user.status === 'Subscribed') {
            await UpstreamService.trackLogin(user.msisdn, store.state.brandConfig.revenuePartnerId);
        }

        return next();
    }
}





