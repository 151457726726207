import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";
import router from "../router";

export default class UpstreamService {
    static async user(token, revenuePartnerId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamService}/user`,
            data: {
                token,
                revenuePartnerId: revenuePartnerId
            }
        });
    }

    static async updateToken(token) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamService}/updateToken`,
            data: {
                token: token
            }
        });
    }

    static async headerEnrichedLogin(encryptedMsisdn) {
        const response = await axios({
            method: 'post',
            url: `${environmentConfig.upstreamService}/user-authentication/encrypted-msisdn-login`,
            data: {
                encryptedMsisdn,
            }
        });

        return response.data.token;
    }

    static async getUserStatus(token) {
        const user = await UpstreamService.invokeGetUserStatus(token);
        if (user.data.token === 'invalidToken') {
            localStorage.removeItem("token");
            return router.push(`/home`);
        }
        return user
    }

    static async invokeGetUserStatus(token) {
        return await axios.post(`${environmentConfig.upstreamService}/get-user-status`, {
            token
        })
    }


    static async updateUserData(msisdn, newUsername, serviceMessages) {
        return axios.post(`${environmentConfig.fantasyHost}/profile/update-user-data`, {
            contentMessages: null,
            onceOffAccessExpiresAt: null,
            msisdn: msisdn,
            username: newUsername,
            serviceMessages: serviceMessages
        })
    }

    static async subscribe(msisdn, sessionId, revenuePartnerId, serviceId, scheme) {
        return await axios.post(`${environmentConfig.upstreamService}/v2/subscription`,
            {
                msisdn: msisdn,
                revenuePartnerId: `${revenuePartnerId}`,
                action: 'Subscription',
                sessionId: sessionId,
                serviceId: serviceId,
                scheme: scheme
            });
    }

    static async unsubscribe(msisdn, sessionId, revenuePartnerId, serviceId, scheme) {
        return await axios.post(`${environmentConfig.upstreamService}/v2/subscription`,
            {
                msisdn: msisdn,
                revenuePartnerId: `${revenuePartnerId}`,
                action: 'Cancellation',
                sessionId: sessionId,
                serviceId: serviceId,
                scheme: scheme
            });
    }

    static async validateUsername(username, userToken) {
        return axios.post(`${environmentConfig.upstreamService}/user-authentication/validate-username`, {
            username: username,
            userToken: userToken
        })
    }

    static async sendOTP(msisdn, revenuePartnerId) {
        return await axios.post(`${environmentConfig.upstreamService}/user-authentication/request-otp`, {
            msisdn: msisdn,
            revenuePartnerId: revenuePartnerId
        })
    }

    static async validateOTP(msisdn, otp) {
        return await axios.post(`${environmentConfig.upstreamService}/user-authentication/validate-otp`, {
            msisdn: msisdn,
            otp: otp,
        })
    }

    static async trackLogin(msisdn, revenuePartnerId) {
        return await axios.post(`${environmentConfig.upstreamService}/track-login`, {
            msisdn: msisdn,
            revenuePartnerId: revenuePartnerId,
            isFantasy: 1,
            isScorePredictor: 0,
            isWeb: 1
        })
    }

    static async checkUserOnNet(msisdn) {
        return await axios.post(`${environmentConfig.upstreamService}/check-user-on-net`, {
            msisdn
        })
    }
}
