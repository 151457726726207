import {createStore} from 'vuex';

export default createStore({
    state: {
        propTeamCaptainSelectPopUp: false,
        user: undefined,
        userLandingStats: undefined,
        brandConfig: undefined,
        isLoading: false,
        activeRounds: undefined,
        roundSelected: undefined,
        upcomingFixtures: undefined,
        termsAndConditionsOpened: false,
        frequentlyAskedQuestionsOpened: false,
        tabTitle: 'Fantasy Football',
        headerEnrichmentAttempted: false,
        userMsisdn: undefined,
        defferedPrompt: null,
        feedArticles: [],
        menuVisible: false,
        showNoQuestions: false,
        freePlayUser: undefined,
    },
    mutations: {
        setPropTeamCaptainSelectPopUp(state, propTeamCaptainSelectPopUp) {
            state.propTeamCaptainSelectPopUp = propTeamCaptainSelectPopUp
        },
        setTermsAndConditionsOpened(state, termsAndConditionsOpened) {
            state.termsAndConditionsOpened = termsAndConditionsOpened
        },
        setFrequentlyAskedQuestionsOpened(state, frequentlyAskedQuestionsOpened) {
            state.frequentlyAskedQuestionsOpened = frequentlyAskedQuestionsOpened
        },
        updateActiveRound(state, updatedRound) {
            if (state.activeRounds) {
                state.activeRounds.forEach((round, index) => {
                    if (updatedRound.roundId === round.roundId) state.activeRounds[index] = updatedRound
                })
            }
        },
        setUserLandingStats(state, userLandingStats) {
            state.userLandingStats = userLandingStats
        },
        setUpcomingStoreFixtures(state, fixtures) {
            state.upcomingFixtures = fixtures
        },
        setSelectedPositionInRound(state, position) {
            state.roundSelected.position = position
        },
        setSelectedRound(state, roundSelected) {
            state.roundSelected = roundSelected
        },
        setUser(state, user) {
            state.user = user
        },
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setRoundsEntered(state, roundsEntered) {
            state.roundsEntered = roundsEntered
        },
        updateUserLandingStatsRoundsEntered(state) {
            if (state.userLandingStats.userStats.roundsEntered !== state.activeRounds.length) state.userLandingStats.userStats.roundsEntered = state.userLandingStats.userStats.roundsEntered + 1
        },
        setActiveRounds(state, activeRounds) {
            state.activeRounds = activeRounds
        },
        setActiveRoundsExpandedOff(state) {
            state.activeRounds.forEach((round) => {
                round.isUpcomingRoundListExpanded = false
            })
        },
        setRoundHasFullTeam(state, roundHasFullTeam) {
            Object.assign(state.roundSelected, {hasFullTeam: roundHasFullTeam});
        },
        setActiveRoundForwardPick(state, player) {
            Object.assign(state.roundSelected.selections, {forward: player});
            Object.assign(state.roundSelected, {hasEntries: true});
        },
        setActiveRoundGoalkeeperPick(state, player) {
            Object.assign(state.roundSelected.selections, {goalkeeper: player});
            Object.assign(state.roundSelected, {hasEntries: true});
        },
        setActiveRoundDefencePick(state, player) {
            Object.assign(state.roundSelected.selections, {defence: player});
            Object.assign(state.roundSelected, {hasEntries: true});
        },
        setHeaderEnrichmentAttempted(state, headerEnrichmentAttempted) {
            state.headerEnrichmentAttempted = headerEnrichmentAttempted;
        },
        setUserMsisdn(state, userMsisdn) {
            state.userMsisdn = userMsisdn;
        },
        clearMidfielders(state) {
            Object.assign(state.roundSelected.selections, {midfielder1: undefined});
            Object.assign(state.roundSelected.selections, {midfielder2: undefined});
        },
        setActiveRoundMidfielderOnePick(state, player) {
            Object.assign(state.roundSelected.selections, {midfielder1: player});
            Object.assign(state.roundSelected, {hasEntries: true});

        },
        setActiveRoundMidfielderTwoPick(state, player) {
            Object.assign(state.roundSelected.selections, {midfielder2: player});
            Object.assign(state.roundSelected, {hasEntries: true});
        },
        setDeferredPrompt(state, defferedPrompt) {
            state.defferedPrompt = defferedPrompt
        },
        setFeedArticles(state, feedArticles) {
            state.feedArticles = feedArticles
        },
        setMenuVisible(state, menuVisible) {
            state.menuVisible = menuVisible
        },
        setShowNoQuestions(state, showNoQuestions) {
            state.showNoQuestions = showNoQuestions
        },
        setFreePlayUser(state, freePlayUser) {
            state.freePlayUser = freePlayUser;
        },
    },
    actions: {},
    modules: {}
})
