import {brandConfigObjectMap} from "@/brand/brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-en'));
brandConfigMap.set('https://staging.fantasysoccer.vodacomfantasy.com', getBrandConfig('vc-za-en'));
brandConfigMap.set('http://192.168.88.88:8080', getBrandConfig('vc-za-en'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        gameTitle: brandConfigObject.gameTitle,
        freePlayServiceId: brandConfigObject.freePlayServiceId,
        baseRoute: brandConfigObject.baseRoute,
        isRandomDrawAwarded: brandConfigObject.isRandomDrawAwarded,
        tabTitle: brandConfigObject.tabTitle,
        userAccessId: brandConfigObject.userAccessId,
        googleTag: brandConfigObject.googleTag,
        revenuePartnerId: brandConfigObject.revenuePartnerId,
    }
}
